import React, { useState } from 'react';
import { dbFirst } from '../../firebase';
import {addDoc, collection} from "firebase/firestore";

const Newsletter = () => {
	const [email, setEmail] = useState('');
	const [alertMessage, setAlertMessage] = useState('');

	const handleSubscribe = async (e) => {
		e.preventDefault();
		if (email.trim() === '') {
			setAlertMessage('Please enter your email address.');
			return;
		}

		try {
			await addDoc(collection(dbFirst, 'subscribers'), {
				email: email.trim(),
				timestamp: new Date()
			});

			setAlertMessage('Thank you for subscribing! 🥐😄');
			setEmail('');
		} catch (error) {
			console.error('Error adding document: ', error);
			setAlertMessage('An error occurred while subscribing. Please try again later.🙉');
		}
	};

	const handleChange = (e) => {
		setEmail(e.target.value);
		setAlertMessage(''); // Clear alert message when email input changes
	};

	return (
		<div>
			{/* Newsletter */}
			<section
				className="relative bg-cover bg-center bg-no-repeat py-32 after:absolute after:inset-0 after:bg-[#000000]/20"
				style={{
					backgroundImage: `url("/images/page-title/9.jpg")`,
				}}
			>
				<div className="container relative z-10">
					<h2 className="mx-auto mb-6 max-w-lg text-center font-display text-2xl text-white">
						Your Journey in French Begins Here: Dive into the Culture and Language!
					</h2>

					<p className="mx-auto max-w-md text-center text-lg text-white">
						Be part of our amazing community and development updates, giveaways, events. Stay tuned
						for the news.more!
					</p>

					<div className="mx-auto mt-10 max-w-md text-center">
						<form className="relative" onSubmit={handleSubscribe}>
							<div className="flex items-center justify-center mb-4">
								<input
									type="email"
									placeholder="Email address"
									className="w-full rounded-full border border-jacarta-600 bg-jacarta-700 py-3 px-4 text-white placeholder-white focus:ring-accent"
									value={email}
									onChange={handleChange}
								/>
								<button type="submit" className="rounded-full bg-accent px-6 py-2 font-display text-sm text-white hover:bg-accent-dark">
									Subscribe
								</button>
							</div>
							{alertMessage && <p className="text-red-500">{alertMessage}</p>}
						</form>
					</div>
				</div>
			</section>
			{/* end newsletter */}
		</div>
	);
};

export default Newsletter;
